.ViewerPaneDiv::-webkit-scrollbar {
    width: 6px;
  }
  
  .ViewerPaneDiv::-webkit-scrollbar-track {
    background-color: #000000;
  }
  
  .ViewerPaneDiv::-webkit-scrollbar-thumb {
    background-color: #5c5c5c;
  }

  .ViewerPaneDiv::-webkit-scrollbar-thumb:hover {
    background-color: #808080;
  }
  
  .ViewerPaneDiv::-webkit-scrollbar-thumb:active {
    background-color: #a0a0a0;
  }

  .ViewerPaneDiv {
    overflow-y: auto;
    height: calc(100vh - 110px);
    padding: 15px;
    margin: 10px;
  }
  
  .centerDiv {
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
  }
  
  .newElementButtonDiv {
    margin-right: 15px;
  }
  

 