

.sticky-header {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #474747;
    padding: 10px;
    width: 100%;  /* Add this line */
    /* Add more styles as needed */
  }
  
  
  .left-section {
    display: flex;
    align-items: center;
  }
  
  .menu-button {
    margin-right: 10px;
    /* Add more styles as needed */
  }
  
  .right-section {
    display: flex;
    align-items: center;
    justify-content: flex-end; /* Right justify the content */
    margin-right: 10px;
  }
  
  .avatar {
    margin-right: 10px;
    /* Add more styles as needed */
  }
  
  .username {
    margin-right: 10px;
    /* Add more styles as needed */
  }
  
  .logout-button {
    margin-left: auto;
    /* Add more styles as needed */
  }