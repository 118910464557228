.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    animation: colorfade 5s infinite;
    background: linear-gradient(0deg, rgb(132, 132, 132) 0%, rgb(33, 1, 108) 100%);
    background-size: 200% 200%;
  }
  
  .login-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  }
  
  @keyframes colorfade {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  