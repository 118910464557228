@keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  

  .fullscan-container-div {
    padding: 20px;
    margin-bottom: 10px;
    border: 1px solid;
    border-radius: 17px;
  }


  .slide-up {
    animation: slideUp 0.5s ease-out forwards;
  }
  
  
  .carousel-item img {
    width: auto;
    height: 100%;
    object-fit: contain;
    margin-right: 10px; /* Adjust the spacing between images as needed */
    justify-content: center;
    align-items: center;
    max-width: 80vw;
    max-height: 80vh;
    margin: 0 auto;
    background-size: contain;
    height: 60vh;
    object-fit: contain;
    margin-right: 10px; /* Adjust the spacing between images as needed */
  }

  .carousel-container {
    overflow: auto;
    white-space: nowrap;
    padding: 10px;

  }
  
  .scrollable-carousel {
    display: flex;
    overflow-x: auto;
  }
  
  .scrollable-carousel::-webkit-scrollbar {
    width: 6px;
  }
  
  .scrollable-carousel::-webkit-scrollbar-track {
    background-color: #000000;
  }
  
  .scrollable-carousel::-webkit-scrollbar-thumb {
    background-color: #5c5c5c;
  }

  .scrollable-carousel::-webkit-scrollbar-thumb:hover {
    background-color: #808080;
  }
  
  .scrollable-carousel::-webkit-scrollbar-thumb:active {
    background-color: #a0a0a0;
  }
  

  