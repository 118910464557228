.construction-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #111010;
}

.construction-text {
    color: #929292;
    margin-bottom: 50px;
    font-size: 2em;
    text-align: center;
}

.construction-animation {
    display: flex;
    justify-content: space-around;
    width: 100px;
}

.bar {
    background-color: #333;
    width: 10px;
    height: 40px;
    animation: bar-animation 1s infinite;
}

.bar:nth-child(2) {
    animation-delay: 0.2s;
}

.bar:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes bar-animation {
    0%, 40%, 100% {
        transform: scaleY(0.4);
    }
    20% {
        transform: scaleY(1);
    }
}
